<template>
    <div class="unit-list-item column" :class="{'component-active': !isCollapsed}"
        :style="{'height': !isCollapsed ?  `${56 + noCollapseComponentHeight + 16}px` : '56px' }"
        >
        <div class="row">
            <img :src="expandIcon" @click="expandContent">
            <span class="legend">
                {{$t('unit') }}
                {{unit.number}}
            </span>
            <span v-tooltip="unit.title || ''">
                {{unit.title}}
            </span>
        </div>
        <template v-if="!isCollapsed">
            <transition-group name="fade">
                <lesson v-for="lesson in unit.lessons" :key="`${studentId} - Lesson: ${lesson.id}`" :unit="unit" :lesson="lesson" />
            </transition-group>
        </template>
    </div>
</template>
<script>
import LessonListItem from './LessonListItem.vue'
export default {
    name: 'UnitListItem', 
    props:{
        unit: Object
    },
    components:{
        'lesson':LessonListItem
    },
    computed:{
        expandIcon(){
            if(this.isCollapsed)
                return require('@/assets/icons/expand_more.svg')
            else
                return require('@/assets/icons/expand_less.svg')
        }, 
        isCollapsed(){
            return this.unit != this.$store.state.selectedUnitForFeedback
        }, 
        studentId(){
            return this.$store.state.selectedStudentForFeedBack.id
        },
        noCollapseComponentHeight(){
            let _height = 0;

            this.unit.lessons.forEach(item => {
                Object.keys(item).forEach(elem => {
                    if(item[elem] &&
                        (elem === "essay" || elem === "multipleChoices"
                        || elem === "vennDiagram" || elem === "writtenAnswers"))
                        _height += 28;
                });

                _height += 36;
            });

            return _height + (this.unit.lessons.length - 1) * 5;
        }
    }, 
    methods:{
        expandContent(){
            this.isCollapsed
                 ? this.$store.commit('updateSelectedUnitForFeedback', this.unit)
                 : this.$store.commit('updateSelectedUnitForFeedback', undefined)
        }
    },   
    watch:{
        'unit.lessons': function(value){
            if(value.length > 0)
                return
            this.$store.commit('removeUnitFromFeedback', this.unit)
        }

    }
}
</script>
<style lang="less" scoped>
.unit-list-item{
	width: 680px;
	border-radius: 4px;
	background-color: #F6F7FA;
    box-shadow: var(--primary-shadow);
    align-content: center;
    margin-top: 8px;
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.5s;

    &.component-active{
        background-color: #fff;
        box-shadow: var(--secondary-shadow);
    }

    .row:first-child{
        margin-bottom: 16px;
        width: 680px;
        align-self: center;
        >img{
            margin-left: 24px;
            margin-right: 32px;
            cursor: pointer;
        }
        span:nth-child(3){
            margin-left: 16px;
            max-height: 24px;
            max-width: 400px;
            min-width: 400px;
            width: 200px;
            color: #3A4DFD;
            font-family: Roboto;
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 24px;
        }
    }

    & /deep/ .lesson-item{
        margin-top: 5px;

        &:first-of-type{
            margin-top: 0px;
        }
    }
}
</style>


