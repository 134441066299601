var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "student-unit-performance-container column" },
    [
      _c("div", { staticClass: "unit-numeration" }, [
        _vm._v(_vm._s(_vm.unit.number))
      ]),
      _c(
        "div",
        { staticClass: "unit-lessons row" },
        _vm._l(_vm.unit.lessons, function(lesson, index) {
          return _c("div", { key: index, staticClass: "unit-lesson column" }, [
            _c("span", [_vm._v(_vm._s("L" + lesson.number))]),
            _c("i", { staticClass: "circle", class: lesson.status }, [
              _c("div", {
                class: [
                  {
                    check: _vm.isApproved(lesson),
                    cross: !_vm.isApproved(lesson)
                  }
                ]
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value:
                      "Submitted At: " + _vm.formatDate(lesson.submittedAt),
                    expression:
                      "`Submitted At: ${formatDate(lesson.submittedAt)}`",
                    modifiers: { top: true }
                  }
                ]
              },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.getLessonScore(lesson))
                )
              ]
            )
          ])
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }