var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "most-visited-section-container" }, [
    _c("div", { staticClass: "most-visited-section-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$t("mostVisitedSections").toUpperCase()))
    ]),
    _c(
      "div",
      { staticClass: "section-header row" },
      [
        _c("label", { attrs: { for: "initialDate" } }, [
          _vm._v(_vm._s(_vm.$t("from")))
        ]),
        _c("datepicker", {
          attrs: {
            language: _vm.datePickerLanguage,
            format: _vm.dateSlashFormatted,
            "disabled-dates": { from: _vm.finalDate }
          },
          model: {
            value: _vm.initialDate,
            callback: function($$v) {
              _vm.initialDate = $$v
            },
            expression: "initialDate"
          }
        }),
        _c("label", { attrs: { for: "finalDate" } }, [
          _vm._v(_vm._s(_vm.$t("to")))
        ]),
        _c("datepicker", {
          attrs: {
            language: _vm.datePickerLanguage,
            format: _vm.dateSlashFormatted,
            "disabled-dates": { to: _vm.initialDate }
          },
          model: {
            value: _vm.finalDate,
            callback: function($$v) {
              _vm.finalDate = $$v
            },
            expression: "finalDate"
          }
        }),
        _c("button", { on: { click: _vm.searchTelemetries } }, [
          _c("span", [_vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("search"))))])
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "section-body" },
      [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("sectionName"))))
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("numberOfVisits"))))
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.withCapitalLetter(_vm.$t("averageDuration")) +
                      " (HH:mm:ss)"
                  )
                )
              ])
            ])
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.partialCollection, function(telemetry, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(telemetry.pageView.name))]),
                  _c("td", { staticClass: "centered-column" }, [
                    _vm._v(_vm._s(telemetry.performanceCounter.counter))
                  ]),
                  _c("td", { staticClass: "centered-column" }, [
                    _vm._v(
                      _vm._s(
                        _vm.fromMiliToFormattedDate(telemetry.averageDuration)
                      )
                    )
                  ])
                ])
              }),
              _vm.partialCollection.length === 0
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "no-content", attrs: { colspan: "3" } },
                      [_vm._v(_vm._s(_vm.$t("noContent")))]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]),
        _c("paginator", {
          attrs: { collection: _vm.collection },
          on: { onPartialCollectionUpdate: _vm.eventHandler }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }