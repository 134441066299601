var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c("div", { staticClass: "question" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("lesson")) +
              " " +
              _vm._s(_vm.lesson.number) +
              " - " +
              _vm._s(_vm.$t(_vm.removeSlashs(_vm.question.type)))
          )
        ]),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.question.audio
              ? _c("AudioToggle", { attrs: { audio: _vm.question.audio } })
              : _vm._e(),
            _c("span", {
              staticClass: "ml-4 pr-4 question",
              attrs: { id: "question" },
              domProps: { innerHTML: _vm._s(_vm.question.question) }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "choices" },
          _vm._l(_vm.question.choices, function(choice, index) {
            return _c(
              "label",
              {
                key: index,
                staticClass: "container",
                class: {
                  underlined:
                    _vm.question.correctChoiceId == choice.id && !choice.correct
                }
              },
              [
                _vm._v(_vm._s(choice.text) + "\n            "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.studentAnswer,
                      expression: "studentAnswer"
                    }
                  ],
                  attrs: { name: _vm.question.id, type: "radio", disabled: "" },
                  domProps: {
                    value: choice.id,
                    checked: _vm._q(_vm.studentAnswer, choice.id)
                  },
                  on: {
                    change: function($event) {
                      _vm.studentAnswer = choice.id
                    }
                  }
                }),
                _c("span", {
                  staticClass: "checkmark",
                  class: _vm.checkMarkStatus
                })
              ]
            )
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }