var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "student-performance-container column studentPerformanceWrap"
    },
    [
      _c("div", { staticClass: "student-performance-body" }, [
        _vm.unitsGroups.length > 0
          ? _c(
              "div",
              _vm._l(_vm.unitsGroups, function(group, index) {
                return _c("units-row", { key: index, attrs: { units: group } })
              })
            )
          : _c("div", { staticClass: "no-content row" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("noContent")))])
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }