<template>
    <div class="student-details-container column">
        <student-details/>
        <tab :pages="pages" @tabChanged="tabChanged"
				:default="defaultPage">
            <component v-if="selectedPage"
                :is="selectedPage.component"/>
		</tab>
    </div>
</template>
<script>

import StudentDetails from "@/components/TeacherFeedback/StudentDetails.vue";
import TeacherFeedBack from "@/views/TeacherFeedBack.vue";
import StudentPerformance from "@/components/BackOffice/Reports/StudentPerformance/StudentPerformance.vue";
import TopVisitedSectionsPerStudent from "@/components/BackOffice/ManagementDashboard/Analytics/TopVisitedSectionsPerStudent.vue";
import Tab from '@/common/Tab.vue'
import AuthenticationService from "@/services/AuthenticationService.js"
import { Role } from '@/utils/Roles.js'

export default {
    name: "StudentReports",
    components:{
        StudentDetails,
        "teacher-feedback": TeacherFeedBack,
        "student-performance": StudentPerformance,
        "most-visited-sections": TopVisitedSectionsPerStudent,
        Tab
    },
	data(){
		return{
            pages:[
                {name:'Performance', component: 'student-performance'}, 
                {name:'Feedback', component: 'teacher-feedback'}, 
                {name:'Analytics', component: 'most-visited-sections'}, 
			], 
            selectedPage: undefined, 
            default: undefined
		}
	},
    computed:{
        currentSection(){
            return this.$store.state.studentReportsCurrentSection;
        },
        studentIdFromRoute(){
            return this.$route.params.studentId
        },
        classrooms(){
            return this.$store.state.classrooms;
        }, 
        defaultPage(){
            return this.default
        }
    },
    methods: {
        initActions(){
            if(this.classrooms.length === 0){
                this.$store.dispatch("loadClassRooms")
                        .then(() => {
                            this.setStudentAndClassroom();
                        });
            }
            else{
                this.setStudentAndClassroom();
            }
        },
        setStudentAndClassroom(){
            this.$store.state.classrooms.forEach(item => {
                let _student = item.students.find(elem => elem.id === this.studentIdFromRoute);

                if(_student){
                    this.$store.commit('updateSelectedStudent', _student);
                    this.$store.commit('updateSelectedClassroom', item);

                    return false;
                }
            });
        }, 
		tabChanged(e){
			this.selectedPage = e
		},
    },
    created(){
        this.default = this.pages[0]
        if(this.$route.query.program){
            this.$router.replace({
            ...this.$router.currentRoute,
            query: { program: false}
            })
            this.default = this.pages.find(e => e.component === 'teacher-feedback')
        }
        this.initActions();
    },
    mounted(){
        this.$store.commit("setClassroomManagementSection", "studentReports");
        let user = AuthenticationService.getProfile()
        if(user.roleName != Role.DistrictTeacher.name){
            this.pages.splice(1,1)
        }
    },
    watch:{
        studentIdFromRoute(){
            this.initActions();
            this.default = this.pages[1]
        }
    }
}
</script>
<style lang="less">
.student-details-container{
    width: 968px;
    margin: 0  auto;
    margin-top: 104px;
    position: relative;

    >.legend{
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .tab{
        margin-top: 24px;
        max-width:704px;
        min-width:704px;

        .tab-body{
            padding: 10px;
        }
    }
}
</style>

