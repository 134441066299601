<template>
    <div class="answer-feedback column" :class="question.type">
        <span>{{$t('lesson')}} {{lesson.number}} - {{$t(question.type)}}</span>
        <span v-html="question.question"></span>
        <p class="suggested-answer" v-if="question.suggestedAnswer"> <i>{{$t('suggestedAnswer')}}</i>: {{question.suggestedAnswer}}</p>
        <pre class="custom-scrollbar"><i>{{$t('studentAnswer')}}</i>: {{question.studentAnswer}}</pre>
        <span>Feedback</span>
        <textarea v-model="feedback" class="custom-scrollbar"></textarea>
        <div class="row">
            <label for="">Score:</label>
            <input type="number" max="100" min="0" v-model="score">
        </div>
    </div>    
</template>
<script>
export default {
    name:'AnswerFeedBack', 
    props:{
        question: Object, 
        lesson: Object
    }, 
    computed:{
        feedback:{
            set(value){
                this.$store.commit('updateAnswerFeedback', {
                    feedback: value, 
                    answer: this.question})
            },
            get(){
                return this.question.feedback
            }
        }, 
        score:{
            get(){
                return this.question.score
            },
            set(value){
                let score = parseInt(value)
                score = Math.round(score)
                score = score > 100 ? 100 : score
                score = score < 0 ? 0 : score
                this.$store.commit('updateAnswerScore', {
                    answer: this.question, 
                    score: score})
            }
            
        }, 
        selectedUnitForFeedback(){
            return this.$store.state.selectedUnitForFeedback
        }
    }
}
</script>
<style lang="less" scoped>
.answer-feedback{
    padding: 16px;
    box-sizing: border-box;
    span:first-child{
        text-transform: capitalize;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }
    span:nth-child(2){
        max-width: 736px;
        white-space: normal;
        color: rgba(0,0,0,0.6);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.25px;
        line-height: 20px;
    }
    pre{
        i{
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            font-size: 14px;
        }
        min-width: 736px;
        max-width: 736px;
        height: 188px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        font-weight: 400;
    }
    textarea{
        margin-top: 16px;
        height: 65px;
        max-width: 736px;
    }
    .row{
        margin-top: 16px;
        input{
            max-width: 80px;
            min-width: 80px;
            height: 30px;
            min-height: 30px;
            margin-left: 16px;
        }
    }

    .suggested-answer{
        i{
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
        }
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        max-width: 736px;
    }


    &.essay{
        pre{
            height: 188px;
        }
    }
    &.written{
        pre{
            height: 65px;
        }
    }
}
</style>


