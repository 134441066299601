var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "units-row-container row" }, [
    _c("div", { staticClass: "row-header column" }, [
      _c("div", [_vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("units"))))]),
      _c("div", [_vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("lessons"))))]),
      _c("div", [_vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("scores"))))])
    ]),
    _c(
      "div",
      { staticClass: "row-body row" },
      _vm._l(_vm.units, function(unit, index) {
        return _c("student-unit-performance", {
          key: index,
          attrs: { unit: unit }
        })
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }