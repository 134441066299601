<template>
    <div class="student-unit-performance-container column">
        <div class="unit-numeration">{{unit.number}}</div>
        <div class="unit-lessons row">
            <div class="unit-lesson column" v-for="(lesson, index) in unit.lessons"
                                    :key="index">
                <span>{{`L${lesson.number}`}}</span>
                <i class="circle" :class="lesson.status">
                    <div :class="[{'check': isApproved(lesson) ,
                                        'cross': !isApproved(lesson)}]"></div>
                </i>
                <span v-tooltip.top="`Submitted At: ${formatDate(lesson.submittedAt)}`">
                    {{getLessonScore(lesson)}}</span>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";

export default {
    name: "StudentUnitPerformance",
    props: {
        unit: Object
    },
    methods: {
        formatDate(date){
            try{
                if(date)
                    return moment(date).format("YYYY/MM/DD");
                else return "N/A"
            }
            catch(exc){
                return date;
            }
        },
        isApproved(lesson){
            return lesson.status !== "failed";
        },
        getLessonScore(lesson){
            return lesson.score != null ? 
                    `${lesson.score !== Math.floor(lesson.score) ? lesson.score.toFixed(2) : lesson.score}%` 
                        : '-';
        }
    }
}

</script>

<style lang="less" scoped>

.student-unit-performance-container{
    min-width: 186px;

    .unit-numeration{
        height: 56px;
        line-height: 56px;
        text-align: center;

        color: #0A1554;
        font-family: Montserrat;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
    }

    .unit-lessons{
        height: 108px;
        justify-content: space-evenly;

        .unit-lesson{
            height: 100%;
            width: 62px;

            span{
                color: rgba(0,0,0,0.6);
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 16px;
                text-align: center;

                width: 100%;
                height: 16px;

                &:hover{
                    cursor: default;
                }
            }

            i{
                align-self: center;
                margin-top: 8px;
            }

            span:last-child{
                color: #3A4DFD;
                font-family: Roboto;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 28px;

                height: 28px;
                
                margin-top: 22px;
            }
        }
    }
}
.studentPerformanceWrap  {
    .student-unit-performance-container{
        .unit-lessons {
            margin: 0;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: flex-start;
            .unit-lesson {
                &.column {
                    span {
                        width: 62px;
                    }
                }
            }
        }
    }
}

</style>