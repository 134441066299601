var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answer-feedback column", class: _vm.question.type },
    [
      _c("span", [
        _vm._v(
          _vm._s(_vm.$t("lesson")) +
            " " +
            _vm._s(_vm.lesson.number) +
            " - " +
            _vm._s(_vm.$t(_vm.question.type))
        )
      ]),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.question.question) } }),
      _vm.question.suggestedAnswer
        ? _c("p", { staticClass: "suggested-answer" }, [
            _c("i", [_vm._v(_vm._s(_vm.$t("suggestedAnswer")))]),
            _vm._v(": " + _vm._s(_vm.question.suggestedAnswer))
          ])
        : _vm._e(),
      _c("pre", { staticClass: "custom-scrollbar" }, [
        _c("i", [_vm._v(_vm._s(_vm.$t("studentAnswer")))]),
        _vm._v(": " + _vm._s(_vm.question.studentAnswer))
      ]),
      _c("span", [_vm._v("Feedback")]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.feedback,
            expression: "feedback"
          }
        ],
        staticClass: "custom-scrollbar",
        domProps: { value: _vm.feedback },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.feedback = $event.target.value
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c("label", { attrs: { for: "" } }, [_vm._v("Score:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.score,
              expression: "score"
            }
          ],
          attrs: { type: "number", max: "100", min: "0" },
          domProps: { value: _vm.score },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.score = $event.target.value
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }