var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.student
    ? _c("div", { staticClass: "student-details column" }, [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.student.firstName[0]) + _vm._s(_vm.student.lastName[0])
            )
          ]),
          _c("label", { attrs: { for: "name" } }, [_vm._v("Name:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.student.firstName) + " " + _vm._s(_vm.student.lastName)
            )
          ]),
          _c("label", [_vm._v(_vm._s(_vm.$t("teacher")))]),
          _c("span", [_vm._v(_vm._s(_vm.teacherName))])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("classPeriod")))]),
          _c("span", [_vm._v(_vm._s(_vm.timePeriod))])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "legend" }, [_vm._v("Student details")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }