<template>
    <div class="most-visited-section-container">
        <div class="most-visited-section-header">
            {{$t("mostVisitedSections").toUpperCase()}}</div>
        <div class="section-header row">
            <label for="initialDate">{{$t("from")}}</label>
            <datepicker v-model="initialDate" 
                        :language="datePickerLanguage" 
                        :format="dateSlashFormatted"
                        :disabled-dates="{ from: finalDate }"></datepicker>

            <label for="finalDate">{{$t("to")}}</label>
            <datepicker v-model="finalDate" 
                        :language="datePickerLanguage" 
                        :format="dateSlashFormatted"
                        :disabled-dates="{ to: initialDate }"></datepicker>
            
            <button v-on:click="searchTelemetries">
                <span>{{withCapitalLetter($t("search"))}}</span>
            </button>
        </div>
        <div class="section-body">
            <table>
                <thead>
                    <tr>
                        <th>{{withCapitalLetter($t("sectionName"))}}</th>
                        <th>{{withCapitalLetter($t("numberOfVisits"))}}</th>
                        <th>{{`${withCapitalLetter($t("averageDuration"))} (HH:mm:ss)`}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(telemetry, index) in partialCollection" :key="index">
                        <td>{{telemetry.pageView.name}}</td>
                        <td class="centered-column">{{telemetry.performanceCounter.counter}}</td>
                        <td class="centered-column">{{fromMiliToFormattedDate(telemetry.averageDuration)}}</td>
                    </tr>
                    <tr v-if="partialCollection.length === 0">
                        <td class="no-content" colspan="3">{{$t("noContent")}}</td>
                    </tr>
                </tbody>
            </table>
            
            <paginator  :collection="collection" 
                        v-on:onPartialCollectionUpdate="eventHandler"/>
        </div>
    </div>
</template>

<script>

import Paginator from "@/common/Paginator.vue";
import StringUtils from "@/utils/StringUtils.js";
import DateTimeUtils from "@/utils/DateTimeUtils.js";
import Datepicker from 'vuejs-datepicker';
import {en, es} from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
    name: "TopVisitedSectionsPerStudent",
    components: {
        Paginator,
        StringUtils,
        DateTimeUtils,
        Datepicker
    },
    data: function(){
        return {
            initialDate: moment().subtract(1, 'd').toDate(),
            finalDate: moment().toDate(),
            partialCollection: []
        }
    },
    computed: {
        collection(){
            return this.$store.state.managementObj.telemetries;
        },
        datePickerLanguage(){
            return this.$i18n.locale === "en" ? en : es;
        },
        userIdFromStudentId(){
            let _student = this.$store.state.selectedClassroom.students
                            .find(elem => elem.id === this.$route.params.studentId);

            return _student.userId;
        }
    },
    methods: {
        eventHandler(event){
            this.partialCollection = event;
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        },
        dateSlashFormatted(date){
            if(!date || date === "")
                return date;

            const enFormat = "MM/DD/YYYY";
            const esFormat = "DD/MM/YYYY";

            let format = this.$i18n.locale === "en" ? enFormat : esFormat;
            return moment(date).format(format);
        },
        dateDashFormatted(date){
            let _date = null;

            return !date || date === "" || !(_date = moment(date)).isValid() ?
                    date : _date.format("YYYY-MM-DD");
        },
        searchTelemetries(){
            let _customDimensions = {
                userId: this.userIdFromStudentId
            };
            this.isLoadingInformation = true
            this.$store.dispatch("getTopVisitedSections", { 
                customDimensions: _customDimensions,
                timespan: this.getQueryTimeSpan()
            })
            .then(null, error => {
                this.$store.commit('addSnackBarNotification', {
                    message: this.$t(error), 
                    dismissable: true
                });
            })
            .finally(()=> {this.isLoadingInformation = false})
        },
        fromMiliToFormattedDate(miliseconds){
            return DateTimeUtils.fromMiliToFormattedTime(miliseconds);
        },
        getQueryTimeSpan(){
            let _initialDate = this.dateDashFormatted(this.initialDate);
            let _finalDate = this.dateDashFormatted(moment(this.finalDate).add(1, 'd'));

            return `${_initialDate}/${_finalDate}`;
        }
    },
    created: function(){
        this.searchTelemetries();
    },
    mounted(){
        this.startTrackingPage({ sectionName: "Most-Visited Page Per Student" });
        this.$store.commit("setAnalyzedManagementSection", "Most-Visited Page Per Student");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Most Visited Page Per Student" });
    }
}

</script>

<style lang="less" scoped>

.most-visited-section-container{
    width: 680px;

    .most-visited-section-header{
        height: 56px;

        color: #0A1554;
        font-family: Montserrat;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 56px;
    }

    .section-header{
        height: 56px;
        color: white;
        background-color: #0A1554;
        line-height: 56px;
        margin-top: 10px;

        button{
            background-color: #FFFFFF;

            height: 40px;
            width: 108px;

            box-sizing: border-box;
            border: 2px solid #4356FD;
            border-radius: 20px;

            margin-top: 8px;
            margin-right: 10px;
            margin-left: auto;

            &:hover{
                cursor: pointer;
            }

            &:focus{
                outline: none;
            }

            span{
                height: 16px;
                width: 175px;
                color: #3A4DFD;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }

        label{
            margin-left: 20px;

            &:last-of-type{
                margin-left: 40px;
            }
        }
 
        & /deep/ .vdp-datepicker{
            color: black;

            input{
                min-height: 24px;
                max-height: 24px;
                margin-left: 10px;
            }
        }
    }

    .section-body{
        max-width: 704px;

        table{
            width: 100%;
            border-collapse: collapse;

            tr{
                height: 56px;
            }

            thead{
                th{
                    text-align: center;
                    width: 30%;

                    &:first-child{
                        text-align: left;
                        width: 40%;
                    }
                }
            }

            tbody{
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;

                .no-content, .centered-column{
                    text-align: center;
                }
            }
        }

        & /deep/ .paginator-container{
            margin-top: 5px;
        }
    }
}

</style>

