var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "student-details-container column" },
    [
      _c("student-details"),
      _c(
        "tab",
        {
          attrs: { pages: _vm.pages, default: _vm.defaultPage },
          on: { tabChanged: _vm.tabChanged }
        },
        [
          _vm.selectedPage
            ? _c(_vm.selectedPage.component, { tag: "component" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }