<template>
    <div class="student-details column" v-if="student">
        <div class="row">
            <div class="legend">Student details</div>
        </div>
        <div class="row">
            <span>{{student.firstName[0]}}{{student.lastName[0]}}</span>
            <label for="name">Name:</label>
            <span>{{student.firstName}} {{student.lastName}}</span>
			<label>{{$t('teacher')}}</label> <span>{{teacherName}}</span>
        </div>
        <div class="row">
			<label>{{$t('classPeriod')}}</label> <span>{{timePeriod}}</span>
		</div>
    </div>
</template>
<script>
export default {
    name: 'StudentDetails', 
    computed:{
        student(){
            return this.$store.state.selectedStudentForFeedBack
        }, 
		classroom(){
			return this.$store.state.selectedClassroom
		}, 
		timePeriod(){
			return ` ${new Date(this.classroom.startDate).toLocaleDateString()} -
					${new Date(this.classroom.endDate).toLocaleDateString()}`
        }, 
        teacherName(){
			let teacher = this.classroom.teachers[0]
			return  `${teacher.firstName} ${teacher.lastName}`
		}
    }
}
</script>
<style lang="less" scoped>
.student-details{
	height: 164px;
	width: 704px;
	margin-top: 20px;
	border-radius: 4px;
	background-color: #F6F7FA;
	box-shadow: var(--primary-shadow);
	position: relative;
	padding: 16px;
	padding-bottom: 24px;
	box-sizing: border-box;

	.row{
		margin-top: 16px;
		.btn:first-of-type{
			margin-left: auto;
			margin-right: 8px;
		}
	}

	.row:first-child{
		margin-top: 0;
		position: relative;
    }
    >.row:nth-child(2){
        span:first-child{
            min-height: 40px;
            min-width: 40px;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            background-color: #3A4DFD;
            text-overflow: none;
            font-weight: 700;
            line-height: 40px;
            text-transform: uppercase;
            cursor: pointer;
            margin-right: 16px;
        }
        >*{
            align-self: center;
        }
    }
    label, span:not(.legend){
        height: 28px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }

    span:not(.legend){
        margin-left: 16px;
        font-weight: 500;
    }
    label:nth-child(3), label:nth-child(4){
        margin-left: 50px;
    }
    .row:nth-child(3){
        margin-left: 20px;
    }
	.modal-message{
		height: 80px;
		span{
			margin: auto;
		}
	}

}
</style>


