var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-list-item column",
      class: { "component-active": !_vm.isCollapsed },
      style: {
        height: !_vm.isCollapsed
          ? 56 + _vm.noCollapseComponentHeight + 16 + "px"
          : "56px"
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("img", {
          attrs: { src: _vm.expandIcon },
          on: { click: _vm.expandContent }
        }),
        _c("span", { staticClass: "legend" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("unit")) +
              "\n            " +
              _vm._s(_vm.unit.number) +
              "\n        "
          )
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.unit.title || "",
                expression: "unit.title || ''"
              }
            ]
          },
          [_vm._v("\n            " + _vm._s(_vm.unit.title) + "\n        ")]
        )
      ]),
      !_vm.isCollapsed
        ? [
            _c(
              "transition-group",
              { attrs: { name: "fade" } },
              _vm._l(_vm.unit.lessons, function(lesson) {
                return _c("lesson", {
                  key: _vm.studentId + " - Lesson: " + lesson.id,
                  attrs: { unit: _vm.unit, lesson: lesson }
                })
              })
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }