<template>
    <div class="teacher-feedback column">
        <div class="teacher-feedback-container column">
            <unit v-for="unit in units" :key="`${student.id} - unit: ${unit.id}`" :unit="unit" />
        </div>
    </div>
</template>
<script>
import  UnitListItem  from "@/components/TeacherFeedback/UnitListItem.vue";

export default {
    name: 'TeacherFeedBack',
    components:{
        'unit': UnitListItem
    },
    computed:{
        units(){
            return this.$store.state.studentContentForFeedBack;
        },
        student(){
            return this.$store.state.selectedStudentForFeedBack
        },         
        clasrooms(){
            return this.$store.state.classrooms
        },
        studentIdFromRoute(){
            return  this.$route.params.studentId
        }
    },
    methods:{
        async getStudentContentForFeedBack(){
            await this.$store.dispatch('getStudentContentForFeedBack', this.studentIdFromRoute)
        }
    },
    mounted(){
        this.getStudentContentForFeedBack();
    },
    watch:{
        studentIdFromRoute(){
            this.getStudentContentForFeedBack()
        }
    }
}
</script>
<style lang="less">
.teacher-feedback{
    width: auto;
    margin: 0  auto;
    position: relative;
}
.teacher-feedback-container{
    width: 960px;
    margin: 0 auto;
    margin-top: 20px;
    >.btn{
        margin: auto;
        margin-top: 24px;
    }
}
</style>

