var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-item column" },
    [
      _c("div", { staticClass: "row" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.lesson.number) + " " + _vm._s(_vm.lesson.title))
        ]),
        _c("span", [
          _vm._v(_vm._s(_vm.$t("score")) + ": " + _vm._s(_vm.score))
        ]),
        _c(
          "button",
          { staticClass: "btn primary", on: { click: _vm.previewLesson } },
          [_vm._v(_vm._s(_vm.$t("preview")))]
        ),
        _c(
          "button",
          { staticClass: "btn secondary", on: { click: _vm.retakeAssessment } },
          [_vm._v("retake")]
        )
      ]),
      _vm.lesson.multipleChoices
        ? _c(
            "span",
            {
              staticClass: "question-link",
              on: {
                click: function($event) {
                  _vm.showMultipleChoiceFeedbackModal = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("multipleChoiceLongName")))]
          )
        : _vm._e(),
      _vm.lesson.vennDiagram
        ? _c(
            "span",
            {
              staticClass: "question-link",
              on: {
                click: function($event) {
                  _vm.showVenDiagramFeedbackModal = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("vennDiagramLongName")))]
          )
        : _vm._e(),
      _vm.lesson.writtenAnswers
        ? _c(
            "span",
            {
              staticClass: "question-link",
              on: {
                click: function($event) {
                  _vm.showWrittenAnswerFeedbackModal = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("writtenLongName")))]
          )
        : _vm._e(),
      _vm.lesson.essay
        ? _c(
            "span",
            {
              staticClass: "question-link",
              on: {
                click: function($event) {
                  _vm.showEssayFeedbackModal = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("essayLongName")))]
          )
        : _vm._e(),
      _vm.showEssayFeedbackModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.updateEssayFeedBack,
                "text-button": "SEND"
              },
              on: {
                close: function($event) {
                  _vm.showEssayFeedbackModal = false
                }
              }
            },
            [
              _c("answer-feed-back", {
                key: _vm.studentId + " - essay: " + _vm.lesson.essay.id,
                attrs: { lesson: _vm.lesson, question: _vm.lesson.essay }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showWrittenAnswerFeedbackModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.updateWrittenAnswersFeedBack,
                "text-button": "SEND"
              },
              on: {
                close: function($event) {
                  _vm.showWrittenAnswerFeedbackModal = false
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "questions-container column pb-5 custom-scrollbar"
                },
                _vm._l(_vm.lesson.writtenAnswers, function(question) {
                  return _c("answer-feed-back", {
                    key: _vm.studentId + " - written question: " + question.id,
                    attrs: { lesson: _vm.lesson, question: question }
                  })
                })
              )
            ]
          )
        : _vm._e(),
      _vm.showMultipleChoiceFeedbackModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.hideModal,
                showCancelButton: false,
                "text-button": _vm.$t("ok")
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "questions-container column pb-5 custom-scrollbar"
                },
                _vm._l(_vm.lesson.multipleChoices, function(question) {
                  return _c("multiple-choice-feedback", {
                    key: _vm.studentId + " - written question: " + question.id,
                    attrs: { lesson: _vm.lesson, question: question }
                  })
                })
              )
            ]
          )
        : _vm._e(),
      _vm.showVenDiagramFeedbackModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.hideModal,
                showCancelButton: false,
                "text-button": _vm.$t("ok")
              }
            },
            [
              _c("venn-diagram-section", {
                attrs: { feedbackQuestion: _vm.lesson }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }